import { Container, Form, FormError, SubmitButton } from 'ui'
import { EmailFormSchema } from './EmailForm.schema'
import { z } from 'zod'
import useDynamicFormSchema from 'hooks/useDynamicFormSchema'
import DynamicForm from '../DynamicForm'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import {
	getDescriptiveKeyData,
	Headers,
	useForm,
	useRecaptcha,
	useToast,
} from 'common'
import Recaptcha from '../Recaptcha'

type EmailFormProps = {
	blok: z.TypeOf<typeof EmailFormSchema>
}

const ACTION = 'custom_form'

const EmailForm = ({ blok }: EmailFormProps) => {
	const { _uid: componentId, recipient, subject, items, scrollId } = blok

	const filteredItems = items.filter(({ type }) =>
		[
			'text',
			'textarea',
			'number',
			'calendar',
			'checkbox',
			'select',
			'email',
			'phone',
			'radio',
			'title',
			'info',
			'multiupload',
		].includes(type)
	)

	const schema = useDynamicFormSchema(filteredItems)
	const form = useForm({ schema: z.object(schema) })
	const { getValues } = form

	const { t } = useTranslation()
	const router = useRouter()
	const { pushError } = useToast()
	const handleRecaptcha = useRecaptcha(ACTION, t)

	const handleSubmit = async (data: Record<string, unknown>) => {
		try {
			const { token, type } = await handleRecaptcha('score')

			if (!token) {
				throw Error()
			}

			const files = [getValues('diploma'), getValues('other')]

			const descriptiveKeyData = getDescriptiveKeyData(data, filteredItems)

			const response = await fetch(`/api/contact/custom`, {
				method: 'POST',
				body: JSON.stringify({
					data: descriptiveKeyData,
					files,
					recipient,
					subject,
					componentId,
					marketingConsent: data.marketingConsent,
				}),
				headers: {
					[Headers.ReCaptchaAction]: ACTION,
					[Headers.ReCaptchaToken]: token,
					[Headers.ReCaptchaType]: type,
				},
			})

			if (response.status === 200) {
				await router.push('/contact/thank-you')
			} else {
				if (response.status === 406) {
					await handleRecaptcha('checkbox')

					return
				}

				pushError(t('Something went wrong... Please try again.'))
			}
		} catch {
			pushError(t('Something went wrong... Please try again.'))
		}
	}

	return (
		<Container className="section h-full" id={scrollId}>
			<Form
				form={form}
				onSubmit={handleSubmit}
				wrapperClass="col-span-2 sm:col-span-3"
				t={t}
			>
				<DynamicForm formData={filteredItems} />
				<Recaptcha className="mb-12" />
				<div className="flex items-center sm:flex-col">
					<FormError className="sm:mb-4" t={t} />
					<SubmitButton className="xs:w-full md:ml-auto lg:ml-auto">
						{t('Send Message')}
					</SubmitButton>
				</div>
			</Form>
		</Container>
	)
}

export default EmailForm
